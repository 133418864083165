import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
// import DatosAdicionales from '../components/afi/datos-adicionales'
import DatosAdicionales from '../components/pages/DatosAdicionales'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const DatosAdicionalesPage = ({ pageContext }) => (
  <GlobalFooterProvider>
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      companyName={pageContext.company}
      gradient
      currentStep={pageContext.step}
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <DatosAdicionales pageContext={pageContext} />
        </>
      )}
    />
  </GlobalFooterProvider>
)

export default DatosAdicionalesPage
